<script>
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import UserSercviceApi from "@/service/api/users";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced table component
 */

import { required } from "vuelidate/lib/validators";

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

const only_image = (val) => {
  if (val) {
    if (val && val.files && val.files[0]) {
      let fileType = val.files[0].file.type;
      const allowedType = ["image/jpeg", "image/png", "image/jpg"];
      return allowedType.includes(fileType);
    } else if (val) {
      return validURL(val);
    } else {
      return false;
    }
  } else {
    return true;
  }
};
export default {
  page: {
    title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      tableData: [],
      title: "Brands",
      userForm: {
        name: "",
        lastName: "",
        image_path: null,
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [10, 15, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      submitted: false,
      hover: true,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name", sortable: true, label: this.$t("brands.table.name") },
        { key: "email", sortable: true },
        { key: "verification", sortable: true },
        { key: "role", sortable: true },
        { key: "balance", sortable: true },
        {
          key: "status",
          sortable: true,
          label: this.$t("brands.table.status"),
        },
      ],
      selectedBrand: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    categoryOptions: function() {
      if (this.categories) {
        return this.categories.map((val) => {
          const options = {
            label: val.name_en,
            value: val.id,
          };
          return options;
        });
      } else {
        return this.categories;
      }
    },
  },
  mounted() {
    UserSercviceApi.getAllUser().then((response) => {
      this.tableData = response.data;
      this.totalRows = this.tableData.length;
    });
  },
  validations: {
    userForm: {
      name: { required },
      image_path: { only_image },
    },
  },
  methods: {
    userSelected(val) {
      const userId = val.pop().id;
      this.$router.push({ path: `${userId}` });
    },
    addUser() {
      console.log("Add user!");
    },
    resetForm() {
      this.userForm.name = "";
      this.userForm.lastName = "";
      this.userForm.image_path = null;
    },
    addUserForm() {
      console.log("Add user!");
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-dropdown
              right
              toggle-class="arrow-none card-drop"
              class="float-right"
              variant="white"
            >
              <template v-slot:button-content>
                <i class="mdi mdi-dots-vertical"></i>
              </template>
              <b-dropdown-item v-b-modal.addUser v-on:click="addUser()"
                >Add User</b-dropdown-item
              >
              <b-dropdown-item>---</b-dropdown-item>
            </b-dropdown>
            <div class="row mt-4" style="padding-top:10px;">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :hover="hover"
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @row-selected="userSelected"
                selectable
              >
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="addUser"
      ref="addUser"
      centered
      title="Add User"
      title-class="font-16"
      size="md"
      hide-footer
    >
      <div class="card">
        <div class="card-body">
          <form class="needs-validation" @submit.prevent>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="validationCustom01">First Name</label>
                  <input
                    id="validationCustom01"
                    v-model="userForm.name"
                    type="text"
                    class="form-control"
                    placeholder="Name English"
                    value="Mark"
                    :class="{
                      'is-invalid': submitted && $v.userForm.name_en.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.userForm.name_en.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.userForm.name_en.required"
                      >Name is required.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="validationCustom02">Last Name</label>
                  <input
                    id="name_ar"
                    v-model="userForm.lastName"
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    value=""
                    :class="{
                      'is-invalid': submitted && $v.userForm.name_ar.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.userForm.name_ar.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.userForm.name_ar.required"
                      >Last name is required.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <FormulateInput
                    type="image"
                    v-model="userForm.image_path"
                    label="Select an image to upload"
                    help="Select a png, jpg or jpeg to upload."
                    :error="
                      submitted && $v.userForm.image_path.$invalid
                        ? 'Please select a png, jpg or jpeg to upload'
                        : null
                    "
                  />
                </div>
              </div>
            </div>
            <div class="mt-4 text-center m-2">
              <button
                class="btn btn-primary w-md waves-effect waves-light float-left"
                type="button"
                v-on:click="addUserForm()"
              >
                Add
              </button>
              <button
                class="btn btn-primary w-md waves-effect waves-light float-right"
                type="button"
                v-on:click="
                  resetForm();
                  $bvModal.hide('addUser');
                "
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>
