import axios from 'axios'
//import authHeader from '../auth-header'
import ConfigService from "../config"
import authHeader from '../auth-header'

const API_URL = ConfigService.base_url;

class UserServiceApi {
    getAllUser() {
        return axios
          .get(API_URL + "users", {
            params: {
              status: "all",
            },
            headers: authHeader()
           
          },
        )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
      getUserDetails(id) {
        return axios
          .get(API_URL + "user", {
            params: {
              user_id: id,
            },
            headers: authHeader()
           
          },
        )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
      getUserAddress(id) {
        return axios
          .get(API_URL + "admin/address", {
            params: {
              id: id,
            },
            headers: authHeader()
           
          },
        )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }
}

export default new UserServiceApi()